<template>
    <div class="card" >
        <Toast />

        <div class="flex align-items-center">
            <i class="pi pi-window-minimize mr-2" style="font-size: 1.5rem;"></i>
            <h3 style="margin:0;">Terminais de Desembarque</h3>
        </div>
        <hr />  

        <DataTable
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines="true"
            @sort="sort($event)"
        >
        <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2">
                        <label>
                            <strong>Descrição</strong>
                        </label>

                        <InputText class="w-full" v-model="filter.descricao" />
                    </div>
                    <div class="field mr-2">
                        <label>
                            <strong>Cnpj</strong>
                        </label>

                        <InputText class="w-full" v-model="filter.cnpj" />
                    </div>
                    

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar ativos"
                        class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>

                    <Button 
                        label="Cadastrar"
                        icon="pi pi-plus"
                        class="p-button-info mt-2 mr-2"
                        @click="cadastrar()"
                    ></Button>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>
            <Column field="razaoSocial" header="Nome"/>
            <Column field="cnpj" header="Cnpj"/>
            <Column field="endereco" header="Endereço"/>
            <Column field="municipio" header="Cidade">
                <template #body="{ data, field }">
                    {{ data[field].nome }} - {{ data[field].uf }}
                </template>
            </Column>
            <Column field="localEntrega" header="Local Entrega"/>
            <Column 
            header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>
                        <Button
                            v-if="data.ativo"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>



        </DataTable>
        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

        <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o terminal: <strong>{{ selecionado.razaoSocial }}</strong>?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
                <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
            </template>
        </Dialog>


        <ModalHistoricoGenerico
            codigoPagina="TERMINAL_DESEMBARQUE"
            :id="selecionado.id"
            :descricao="descricaoHistoricoDialog"
            :visivel="historicoDialog"
            @fechar="ocultarHistorico()"
        />
    </div>

</template>

<script>
import StorageService from '../../service/StorageService';
import TerminalDesembarqueService from '../../service/TerminaisDesembarqueService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';

export default {
    name: 'TerminalDesembarqueList',
    components: { ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,

            data: [],

            filter: {},
            selecionado: {},

            ordenar: 'id,asc',
            historicoDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: null,
            acoes: '',

            storange: StorageService.getControlePagina("TERMINAL-DESEMBARQUE"),    
            storangeMessage: StorageService.getMensagemToast("TERMINAL-DESEMBARQUE")  
        }
    },

    created() {
        //verifica se o filtro está vazio
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        exibirMensagemToast() {
            if (this.storangeMessage.active){
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail, 
                    life: 7500
                });
                StorageService.setMensagemToast("TERMINAL-DESEMBARQUE", 'info', '', '', false);
            }
        },
        cadastrar() {
            this.$router.push(`/terminais/desembarque/cadastro`);
        },
        editar(terminalId) {                
            this.$router.push(`/terminais/desembarque/editar/${ terminalId }`);
        },
        
        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            TerminalDesembarqueService.buscarComPaginacao(this.filter)
            .then(({ data }) => {
                if(data) {
                    this.data = data.content;
                    this.qtdRegistroPorPagina = data.pageable.pageSize;
                    this.totalRegistro = data.totalElements;
                }
            }).catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar terminal de desembarque',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
                StorageService.setControlePaginaFilter("TERMINAL-DESEMBARQUE", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },
        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        limparFiltro() {
            this.filter["descricao"] = undefined;
            this.filter["cnpj"] = undefined;

            this.carregarDados();
        },

        confirmarAtivarOuInativar(embarque) {
            this.selecionado = embarque;
            this.ativarOuInativarDialog = true;
        },

        visualizarHistorico(terminal) {
            this.descricaoHistoricoDialog = "Terminal: "+ terminal.razaoSocial;
            this.historicoDialog = true;
            this.selecionado = terminal;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        ativarOuInativar() {
            if(this.selecionado.ativo) {
                TerminalDesembarqueService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O terminal foi inativado com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                TerminalDesembarqueService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O terminal foi ativado com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },


    }


}
 
</script>