<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <i class="pi pi-comments mr-2" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Terminal de Desembarque</h3>
            </div>  
        </div>

        <hr />

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label>
                        <strong>Razão Social<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.razaoSocial" id="modalidade" />
                    
                </div>
            </div>

            <div class="col-10 sm:col-6 md:col-6 lg:col-6 xl:col-5">
                <div class="field">
                    <label for="modalidade">
                        <strong>Nome Fantasia<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.nomeFantasia" id="modalidade" />
                </div>
            </div>

        </div>
        <div class="grid p-fluid formgrid mt-3">
            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label>
                        <strong>Endereço<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.endereco" id="modalidade" />
                    
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="modalidade">
                        <strong>Bairro<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.bairro" id="modalidade" />
                </div>
            </div>
            <div class="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="modalidade">
                        <strong>CNPJ<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.cnpj" @input="formatarCnpj" maxlength="18" id="cnpj" />
                </div>
            </div>
            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-2">
                <div class="field">
                    <label for="modalidade">
                        <strong>Inscrição Estadual<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.inscricaoEstadual" maxlength="12" id="modalidade" />
                </div>
            </div>

        </div>

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3">
                        <div class="field">
                            <label for="imovel"><strong>Municipio<span style="color:red;">&nbsp;*</span></strong></label>
                            <AutoComplete      
                                field="label"                                            
                                :dropdown="true" 
                                class="w-full"
                                @complete="carregarMunicipio($event)"
                                placeholder="Informe o código ou o nome do Municipio."
                                v-model="dados.municipio"
                                :suggestions="municipioFiltro"
                                :forceSelection="true"
                               ></AutoComplete> </div>
                    </div>
            <div class="col-4 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="modalidade">
                        <strong>Cep<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.cep" @input="formatarCep" maxlength="9" id="cep"/>
                </div>
            </div>
            <div class="col-9 sm:col-6 md:col-6 lg:col-6 xl:col-5">
                <div class="field">
                    <label for="modalidade">
                        <strong>Código Alfadegado</strong>
                    </label>
                    <InputText v-model="dados.codigoAlfadegado" maxlength="12" id="codigoAlfandegado" />
                </div>
            </div>

        </div>

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-9 md:col-9 lg:col-9 xl:col-11">
                <div class="field">
                    <label for="modalidade">
                        <strong>Local de Descarga</strong>
                    </label>
                    <InputText v-model="dados.localEntrega" id="localDescarga" maxlength="255" />
                </div>
            </div>
        </div>
        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-9 md:col-9 lg:col-9 xl:col-11">
                <div class="field">
                    <label for="observacao">
                        <strong>Observação</strong>
                    </label>
                    <Textarea id="value" rows="4" v-model="dados.observacao" cols="30" aria-describedby="text-error" maxlength="1000"  />
                </div>
            </div>

        

        </div>

        <div class="flex align-items-center mt-3">
            <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-info mr-2" @click="salvar()" :disabled="doubleClick"></Button>
            <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2"  @click="voltar()"></Button>
        </div>

    </div>
</template>
<script>
import MunicipioService from '../../service/MunicipioService';
import StorageService from '../../service/StorageService';
import TerminaisDesembarqueService from '../../service/TerminaisDesembarqueService';

export default {

    props: {
        id: {
            type: Number,
            require: false
        }
    },

    data() {
        return {
            dados: {
                terminal: {},
                municipio: null,
                ativo: true
                

            },
            municipioFiltro: []
        }
    },


            methods: {
                voltar() {
                    this.$router.push(`/terminais/desembarque`);
                },

                salvar() {
                    if(!this.validarCampos()) return;
                    const dados = this.montarJson();
                    TerminaisDesembarqueService.salvar(dados)
                        .then(({ data }) =>{
                            if(!!data) {
                                const detalhe = 'Terminal de Desembarque salvo com sucesso!.';
                                StorageService.setMensagemToast("TERMINAL-DESEMBARQUE", 'success', 'Operação realizada!', detalhe, true);
                                this.voltar();
                            }
                        })
                        .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Não foi possível salvar o terminal.',
                            detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                            life: 7500
                        }

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
                    
                },

                validarCampos(){
                    let campos = [];
                    if(!this.dados.municipio){
                        campos.push("municipio");
                        this.campoInvalido;
                    }
                    if(!this.dados.razaoSocial){
                        campos.push("Razão social");
                        this.campoInvalido;
                    }
                    if(!this.dados.nomeFantasia){
                        campos.push("Nome Fantasia");
                        this.campoInvalido;
                    }
                    if(!this.dados.endereco){
                        campos.push("Endereço");
                        this.campoInvalido;
                    }
                    if(!this.dados.bairro){
                        campos.push("Bairro");
                        this.campoInvalido;
                    }
                    if(!this.dados.cnpj){
                        campos.push("CNPJ");
                        this.campoInvalido;
                    } if(!this.dados.cep){
                        campos.push("CEP");
                        this.campoInvalido;
                    } if(!this.dados.inscricaoEstadual){
                        campos.push("Inscrição estadual");
                        this.campoInvalido;
                    }
                    if(campos.length > 0){
                        let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                        if(campos.length > 1) {
                            mensagem = "Por favor, preencher os campos: ";
                            mensagem += campos.join(", ");

                            const indice = mensagem.lastIndexOf(", ");
                            mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                        }

                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Não foi possível salvar o Terminal de Desembarque.',
                            detail: mensagem, 
                            life: 15000
                        });
                    }

                    return campos.length === 0;
                },

                carregarMunicipio(event){
                    const codigoOuDescricao = !event.query.trim().length ? 'a': event.query;
                    MunicipioService.buscarPorDescricao(codigoOuDescricao)
                    .then(({ data }) => {
                        if(!!data && data.length > 0) {
                            this.municipioFiltro = data.map(municipio => {
                                municipio["label"] = `${ municipio.nome } - ${ municipio.uf }`;
                                return municipio;
                            });
                        } else{
                            this.municipioFiltro = [];
                        }
                    })
                    .catch(error => {
                        this.municipioFiltro = [];
                    });
                },

                montarJson() {
                    const obj = {};
                    obj["id"] = this.dados.id;
                    obj["razaoSocial"] = this.dados.razaoSocial;
                    obj["nomeFantasia"] = this.dados.nomeFantasia;
                    obj["endereco"] = this.dados.endereco;
                    obj["bairro"] = this.dados.bairro;
                    obj["cnpj"] = this.dados.cnpj;
                    obj["localEntrega"] = this.dados.localEntrega;
                    obj["cep"] = this.dados.cep;
                    obj["codigoAlfandegado"] = this.dados.codigoAlfadegado;
                    obj["inscricaoEstadual"] = this.dados.inscricaoEstadual;
                    obj["observacao"] = this.dados.observacao;
                    obj["municipio"] = this.dados.municipio;
                    obj["ativo"] = this.dados.ativo;
                   
                    return obj;
                },

                preencherForm(data) {
                   this.dados = data;
                   if(!!data.municipio) this.dados.municipio["label"] = `${ data.municipio.nome } - ${ data.municipio.uf }`;

                },
                formatarCnpj() {
                    let cnpjSemFormato = this.dados.cnpj.replace(/[^\d]/g, '');
                    if (cnpjSemFormato.length > 14) {
                        cnpjSemFormato = cnpjSemFormato.substring(0, 14);
                    }
                    if (cnpjSemFormato.length >= 2) {
                        cnpjSemFormato = cnpjSemFormato.substring(0, 2) + '.' + cnpjSemFormato.substring(2);
                    }
                    if (cnpjSemFormato.length >= 6) {
                        cnpjSemFormato = cnpjSemFormato.substring(0, 6) + '.' + cnpjSemFormato.substring(6);
                    }
                    if (cnpjSemFormato.length >= 10) {
                        cnpjSemFormato = cnpjSemFormato.substring(0, 10) + '/' + cnpjSemFormato.substring(10);
                    }
                    if (cnpjSemFormato.length >= 15) {
                        cnpjSemFormato = cnpjSemFormato.substring(0, 15) + '-' + cnpjSemFormato.substring(15);
                    }
                    this.dados.cnpj = cnpjSemFormato;
                },
                formatarCep() {
                    let cepSemFormato = this.dados.cep.replace(/[^\d]/g, '');
                    if (cepSemFormato.length > 8) {
                        cepSemFormato = cepSemFormato.substring(0, 8);
                    }
                    if (cepSemFormato.length >= 5) {
                        cepSemFormato = cepSemFormato.substring(0, 5) + '-' + cepSemFormato.substring(5);
                    }
                    this.dados.cep = cepSemFormato;
                },
                            
            },
            mounted() {
                if(!!this.id) {
                    TerminaisDesembarqueService.buscarPorId(this.id)
                        .then(({ data }) => {
                            this.preencherForm(data);
                        })
                        .catch(error => {
                            this.dados = {};

                            const toast = {
                                severity: 'error',
                                summary: `Falha ao buscar os dados do Terminal de Descarga.`,
                                detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                                life: 7500
                            }

                            if(!!error && !!error.response && !!error.response.data) {
                                const { message, exception } = error.response.data;

                                if(!!exception) {
                                    toast["detail"] = exception;
                                }
                            }

                            this.$toast.add(toast);
                        });
                }
            }


        }




</script>